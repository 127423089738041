<template>
    <CInput
      :label="label"
      :value="formattedValue"
      @input="onInput"
      @blur="onBlur"
      horizontal
    />
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: Number, // Use Number for the raw value
        default: null,
      },
      label: {
        type: String,
        default: "",
      },
    },
    computed: {
      formattedValue() {
        // Format the value with 2 decimal places for display
        return this.value !== null ? this.value.toFixed(2) : "";
      },
    },
    methods: {
      onInput(event) {
        const inputValue = parseFloat(event.target.value);
        // Emit the raw number to the parent
        this.$emit("input", isNaN(inputValue) ? null : inputValue);
      },
      onBlur() {
        // Ensure value is rounded to 2 decimal places on blur
        if (this.value !== null) {
          this.$emit("input", parseFloat(this.value.toFixed(2)));
        }
      },
    },
  };
  </script>
  