<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <strong> SalesReport </strong> Information
            </CCardHeader>
            <CCardBody>
              <CForm>
                <CInput
                  label="Stock No"
                  v-model="obj.stockNo"
                  horizontal
                  readonly
                />
                <CInput
                  label="Registration Date"
                  v-model="vehicleRegstrationDate"
                  horizontal
                  readonly
                />
                <CInput
                  label="Registration No"
                  v-model="vehicleRegistrationNumber"
                  horizontal
                  readonly
                />
                <CInput label="Brand" v-model="brandName" horizontal readonly />
                <CInput
                  label="Brand"
                  v-model="obj.vehicle.model.name"
                  horizontal
                  readonly
                />
                <CInput
                  label="Chasis No"
                  v-model="chasisNo"
                  horizontal
                  readonly
                />
                <CInput
                  label="Customer"
                  :value="customerName"
                  horizontal
                  readonly
                />
                <CInput
                  label="Salesman"
                  v-model="salesmanName"
                  horizontal
                  readonly
                />
                <h4>Sales</h4>

                <CInput
                  label="Body Price"
                  prepend="RM"
                  v-model="obj.purchase.bodyPriceLocalCurrency"
                  horizontal
                />
                <CInput
                  label="Selling Price"
                  prepend="RM"
                  v-model="obj.sale.saleAmount"
                  horizontal
                />
                <CInput
                  label="HP Amount"
                  prepend="RM"
                  v-model="obj.sale.hpAmount"
                  horizontal
                />

                <!-- <CurrencyInput
                  label="Selling Price"
                  v-model="obj.sale.saleAmount"
                  horizontal
                /> -->
                <h4>Purchase Cost</h4>
                <CInput
                  label="Supplier Currency"
                  horizontal
                  v-model="obj.purchase.supplierCurrency"
                />
                <CInput
                  label="Purchase Price (Foreign)"
                  :prepend="obj.purchase.supplierCurrency"
                  v-model="obj.purchase.vehiclePriceSupplierCurrency"
                  horizontal
                />
                <CInput
                  label="Purchase Price"
                  prepend="RM"
                  v-model="obj.purchase.vehiclePriceLocalCurrency"
                  horizontal
                  description="TT"
                />
                <CInput
                  label="Duty Tax"
                  prepend="RM"
                  v-model="obj.adminitrativeCost.dutyCostAmount"
                  horizontal
                />
                <CInput
                  label="Ap Amount"
                  prepend="RM"
                  v-model="obj.adminitrativeCost.apCostAmount"
                  horizontal
                />
                <CInput
                  label="Forwarding Amount"
                  prepend="RM"
                  v-model="obj.adminitrativeCost.forwardingCostAmount"
                  horizontal
                />
                <CInput
                  label="Int FS Amount"
                  prepend="RM"
                  v-model="obj.adminitrativeCost.intFsCostAmount"
                  horizontal
                />
                <CInput
                  prepend="RM"
                  label="Insurance"
                  v-model="obj.adminitrativeCost.insuranceAmount"
                  horizontal
                />
                <CInput
                  prepend="RM"
                  label="Puspakom, Reg, R.Tax"
                  v-model="obj.adminitrativeCost.puspakomRegRoadTax"
                  horizontal
                />

                <CInput
                  label="Svc EzCare Amount"
                  prepend="RM"
                  v-model="obj.expense.serviceEzCareCostAmount"
                  horizontal
                />
                <CInput
                  label="Interior Amount"
                  prepend="RM"
                  v-model="obj.expense.interiorCostAmount"
                  horizontal
                />
                <CInput
                  label="Paint Amount"
                  prepend="RM"
                  v-model="obj.expense.paintCostAmount"
                  horizontal
                />
                <CInput
                  label="Tyre Amount"
                  prepend="RM"
                  v-model="obj.expense.tyreCostAmount"
                  horizontal
                />
                <CInput
                  label="Rental Amount"
                  prepend="RM"
                  v-model="obj.expense.rentalCostAmount"
                  horizontal
                />

                <h4>Sales Cost</h4>
                <CInput
                  label="Salesman Commision"
                  prepend="RM"
                  v-model="obj.sale.salesmanCommisionAmount"
                  horizontal
                />
                <CInput
                  label="Promotion Discount"
                  prepend="RM"
                  v-model="obj.sale.promotionDiscountAmount"
                  horizontal
                />
                <h4>Loan</h4>
                <CInput
                  label="Bank"
                  v-model="bankLoanName"
                  horizontal
                  readonly
                />
                <CInput
                  prepend="RM"
                  label="Loan Amount"
                  v-model="bankLoanAmount"
                  horizontal
                />
                <h4>Others</h4>
              </CForm>
            </CCardBody>
            <CCardFooter>
              <CButton
                type="submit"
                size="sm"
                color="primary"
                @click="onSubmit"
              >
                <CIcon name="cil-check-circle" /> Submit
              </CButton>

              <CButton class="ml-1" color="secondary" @click="cancel">
                Cancel
              </CButton>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import StockApi from "../../lib/stockApi";
import { required, minLength } from "vuelidate/lib/validators";
import moment from "moment";
import CurrencyInput from "../widgets/CurrencyInput.vue"; // Import the reusable component

export default {
  name: "SalesReport",
  components: {
    CurrencyInput,
  },
  data: () => {
    return {
      infoList: [],
      api: new StockApi(),
      bankLoanName: "",
      bankLoanAmount: 0,
      obj: {
        name: "",
      },
      submitted: false,
    };
  },
  mounted() {
    var self = this;
    this.obj = this.getEmptyObj();

    self.resetObj();
  },
  validations: {
    obj: {
      name: {
        required,
        minLength: minLength(2),
      },
      // code: {
      //   required,
      //   minLength: minLength(2),
      // },
      // country: {
      //   required,
      // },
    },
  },

  computed: {
     chasisNo() {
      try {
        return this.obj.vehicle.chasisNo;
      } catch (error) {
        return "N/A";
      }
    },
    modelName() {
      try {
        return this.obj.vehicle.model.name;
      } catch (error) {
        return "N/A";
      }
    },
    brandName() {
      try {
        return this.obj.vehicle.brand.name;
      } catch (error) {
        return "N/A";
      }
    },

    vehicleRegistrationNumber() {
      try {
        return this.obj.registration.vehicleRegistrationNumber;
      } catch (error) {
        return "N/A";
      }
    },
    salesmanName() {
      try {
        return this.obj.sale.salesmanName;
      } catch (error) {
        return "N/A";
      }
    },
    customerName() {
      try {
        return this.obj.sale.customer.name;
      } catch (error) {
        return "N/A";
      }
    },
    vehicleRegstrationDate() {
      if (
        this.obj &&
        this.obj.registration &&
        this.obj.registration.vehicleRegistrationDate
      )
        return this.getDisplayDate(
          this.obj.registration.vehicleRegistrationDate
        );
      return "N/A";
    },
    formString() {
      return JSON.stringify(this.obj, null, 4);
    },
    isValid() {
      return !this.$v.obj.$invalid;
    },
    isDirty() {
      return this.$v.obj.$anyDirty;
    },
  },
  methods: {
    getDisplayDate(item) {
      return moment(item).format("YYYY/MM/DD");
    },
    checkIfValid(fieldName) {
      const field = this.$v.obj[fieldName];
      return !(field.$invalid || field.$model === "");
    },
    onSubmit() {
      var self = this;
      // this.obj.sale.saleAmount = (
      //   Number(this.obj.sale.saleAmount) || 0
      // ).toFixed(2);
      self.obj.sale.loan.approvedLoanAmount = self.bankLoanAmount;
      if (!self.obj.id) {
        this.api
          .create(self.obj)
          .then((response) => {
            // self.$router.push({ path: "/admins/BankList" });
            self.toast("Message", "Updated", "success");
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        console.log("Obj befor update", this.obj.sale.saleAmount);
        this.api
          .update(self.obj)
          .then((response) => {
            // self.$router.push({ path: "/admins/BankList" });

            self.toast("Message", "Updated", "success");
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    resetObj() {
      var self = this;
      if (self.$route.params.id) {
        this.api
          .get(self.$route.params.id)
          .then((response) => {
            self.obj = response.result;
            self.bankLoanName = this.getBankLoanName(self.obj);
            self.bankLoanAmount = this.getBankLoanAmount(self.obj);
            console.log(self.obj);
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        self.obj = self.getEmptyObj();
      }
    },
    getBankLoanAmount(item) {
      try {
        return item.sale.loan.approvedLoanAmount;
      } catch (error) {}
      return 0;
    },

    getBankLoanName(item) {
      try {
        return item.sale.loan.bank.name;
      } catch (error) {}
      return "N/A";
    },
    getEmptyObj() {
      return {
        name: "",
        address: "",
        country: "",
        phone: "",
        website: "",
        contactPersonName: "",
        contactPersonPhone: "",
        contactPersonEmail: "",
      };
    },
    cancel() {
      this.$router.push({ path: "/admins/SalesReportList" });
    },
  },
};
</script>
